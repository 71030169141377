/* eslint-disable quote-props */
/* eslint-disable no-await-in-loop */
import firebase from '../config/Firebase';

export const createUserAndAgency = async (userInfos, agencyInfos, next) => {
  try {
    let userId = null;
    const newUserInfos = userInfos;
  
    const user = await firebase.firestore().collection('users').where('email', '==', userInfos.email).get();

    if (user.docs[0]) {
      delete newUserInfos.password;
      editCurrentUser(newUserInfos, agencyInfos, user.docs[0], next);
    } else {
      await firebase.auth().createUserWithEmailAndPassword(userInfos.email, userInfos.password)
        .then((userCredential) => {
          userId = userCredential.user.uid;
    
          delete newUserInfos.password;
          firebase.firestore().collection('users').doc(userId).set({
            ...newUserInfos,
            image: '',
            role: 'cae',
          });
    
          firebase.firestore().collection('Companies').doc(userId).set({ ...agencyInfos, experiences: [] });
    
          next({
            ...newUserInfos,
            image: '',
            role: 'cae',
          }, userId);
        })
        .catch((error) => {
          return error.message;
        });
    }
  } catch (error) {
    return false;
  }
};

export const getUser = async (uid) => {
  const user = await firebase.firestore().collection('users').doc(uid).get();
  return user.exists ? user.data() : null;
}

export const loginCae = async (email, password, next) => {
  const userDoc = await firebase.firestore().collection('users').where('email', '==', email).get();
  const user = userDoc.docs[0];

  if (!user) return 'Email inválido!';

  const role = userDoc.docs[0].data().role;

  if (!['cae', 'caeAdmin'].includes(role))
    return 'Usuário sem permissão para login no Caê';

  return firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
    next(userDoc.docs[0].data(), user.user.uid);
  }).catch((error) => {
    switch (error.code) {
      case 'auth/invalid-email':
        return 'Email incorreto!';
      case 'auth/wrong-password':
        return 'Senha incorreta!';
      case 'auth/user-not-found':
        return 'Usuario não encontrado!';
      default:
        return 'Algo deu errado no login. Tente novamente.';
    }
  });
};

export const handleGetCompany = async (uid) => {
  const companiesDocs = await firebase.firestore().collection('Companies').doc(uid).get();

  return companiesDocs.data();
};

export const getAllExperiences = async () => {
  const companiesDocs = await firebase.firestore().collection('Companies').get();

  let allExperiences = [];
  companiesDocs.docs.map((doc) => {
    const experiences = doc.data().experiences;
    if (experiences.length > 0) {
      allExperiences = allExperiences.concat(experiences.map((exp) => {
        return { ...exp, company: doc.data().name, city: doc.data().city };
      }));
    }
  });

  return allExperiences;
};

export const createExperience = async (company, experience, uid, index, finishProcess, setCompanyInfos, setExpPopup, setProgress, setTextLoading) => {
  try {
    let urlFotos = [];
    setProgress((prog) => prog + 10);

    if (experience.urlFotos && experience.urlFotos.length > 0) {
      setTextLoading('Fazendo upload de imagens...');
      urlFotos = await saveImages(experience.urlFotos, uid, setProgress);
    } else {
      setProgress((prog) => prog + 60);
    }

    const newExperiences = company.experiences;
    const { totalPoints, totalPreparationPoints, totalPromotionPoints } = experienceCalculator({ ...experience, urlFotos: urlFotos });
    newExperiences.push({ ...experience, urlFotos: urlFotos, classification: totalPoints, preparationPoints: totalPreparationPoints, promotionPoints: totalPromotionPoints });
    
    setProgress((prog) => prog + 14);
    setTextLoading('Criando documento...');

    await firebase.firestore().collection('Companies').doc(uid).set({ ...company, experiences: newExperiences });
    
    setTextLoading('Finalizando...');
    setProgress((prog) => prog + 16);
    setCompanyInfos({ ...company, experiences: newExperiences });
    finishProcess(false);
    setExpPopup(true);
  } catch (error) {
    // If have any trouble, try to save information one more time in database
    if (experience.urlFotos) {
      await reuploadFiles('create', company, experience, uid, index, finishProcess, setCompanyInfos, setExpPopup);
    }
  }
};

export const editExperience = async (company, experience, uid, index, finishProcess, setCompanyInfos, setExpPopup, setProgress, setTextLoading) => {
  try {
    let urlFotos = [];
    
    setProgress((prog) => prog + 10);
    if (experience.urlFotos && experience.urlFotos.length > 0) {
      setTextLoading('Fazendo upload de imagens...');
      urlFotos = await saveImages(experience.urlFotos, uid, setProgress);
    } else {
      setProgress((prog) => prog + 60);
    }

    const newExperiences = company.experiences;
    const { totalPoints, totalPreparationPoints, totalPromotionPoints } = experienceCalculator({ ...experience, urlFotos: urlFotos });
    newExperiences[index] = { ...experience, urlFotos: urlFotos, classification: totalPoints, preparationPoints: totalPreparationPoints, promotionPoints: totalPromotionPoints };

    setProgress((prog) => prog + 14);
    setTextLoading('Atualizando documento...');

    await firebase.firestore().collection('Companies').doc(uid).set({ ...company, experiences: newExperiences });

    setTextLoading('Finalizando...');
    setProgress((prog) => prog + 16);
    setCompanyInfos({ ...company, experiences: newExperiences });
    finishProcess(false);
    setExpPopup(true);
  } catch (error) {
    // If have any trouble, try to save information one more time in database
    if (experience.urlFotos) {
      await reuploadFiles('edit', company, experience, uid, index, finishProcess, setCompanyInfos, setExpPopup);
    }
  }
};

export const deleteExperience = async (company, experience, uid, index) => {
  const newExperiences = [...company.experiences];
  newExperiences.splice(index, 1);

  // Deleting the Experience by position on company's array
  await firebase.firestore().collection('Companies').doc(uid).set({ ...company, experiences: newExperiences });

  // Deleting the images from Experience
  if (experience.urlFotos && experience.urlFotos.length > 0) {
    experience.urlFotos.map(async (img) => {
      const refImage = firebase.storage().ref(`cae/${uid}/${img.name}`);
      await refImage.delete();
    });
  }
}

const saveImages = async (photos, uid, setProgress) => {
  const urlFotos = [];

  for (let indexImg = 0; indexImg < photos.length; indexImg++) {
    const img = photos[indexImg];
    setProgress((prog) => prog + 20);
    if (img.file !== '') {
      const ref = firebase.storage().ref(`cae/${uid}/${img.name}`);
      await ref.put(img.file).then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then((url) => {
          urlFotos.push({
            name: img.name,
            lastModifiedDate: img.lastModifiedDate,
            size: img.size,
            src: url,
            file: '',
          });
        });
      });
    } else
      urlFotos.push(img);
  }

  return urlFotos;
}

export const saveContact = async (userInfos) => {
  await firebase.firestore().collection('Contacts').add(userInfos);
};

const editCurrentUser = (userInfos, agencyInfos, user, next) => {
  firebase.firestore().collection('users').doc(user.id).set({ ...user.data(), ...userInfos, role: 'cae' })
    .then(() => {
      firebase.firestore().collection('Companies').doc(user.id).set({ ...agencyInfos, experiences: [] });
  
      next({ ...user.data(), ...userInfos, role: 'cae' }, user.id);
    })
    .catch((error) => {
      return error.message;
    });
};

export const getAllCompanies = async () => {
  const companiesDocs = await firebase.firestore().collection('Companies').get();

  return companiesDocs.docs;
};

export const resetPassword = async (email) => {
  try {
    if (!email.includes('@'))
      throw new Error('Email inválido!');

    const docsUsers = await firebase.firestore().collection('users').where('email', '==', email).get();

    if (docsUsers.docs.length === 0)
      throw new Error('Email inválido!');
    
    await firebase.auth().sendPasswordResetEmail(email);

    return '';
  } catch (error) {
    return error.message;
  }
};

export const editCompany = async (company, _, uid, __, finishProcess, setCompanyInfos, ___, setProgress, setTextLoading) => {
  setProgress((prog) => prog + 55);
  setTextLoading('Editando documento...');

  await firebase.firestore().collection('Companies').doc(uid).set(company);

  setTextLoading('Finalizando...');
  setProgress((prog) => prog + 45);
  setCompanyInfos(company);
  finishProcess(false);
}

function radioCalculatorType(value, values, weight, max) {
  return Math.min(values[value] * weight, max);
}

function checkboxCalculatorType(value, values, weight, max) {
  const keys = Object.keys(value);

  let totalPoints = 0;
  keys.forEach((key) => {
    totalPoints += values[key];
  });

  return Math.min(Math.ceil(totalPoints) * weight, max);
}

function checkboxCountCalculatorType(value, values, weight, max) {
  const keys = Object.keys(value);
  let finalPoints = 0;

  let totalPoints = 0;
  keys.forEach((key) => {
    totalPoints += values[key];
  });

  if (totalPoints >= 1 && totalPoints <= 2)
    finalPoints = 1;
  else if (totalPoints <= 4)
    finalPoints = 3;
  else
    finalPoints = 4;

  return Math.min(finalPoints * weight, max);
}

function multipleRadioCalculatorType(value, values, weight, max) {
  const keys = Object.keys(value);

  let points = 0;
  keys.forEach((key) => {
    points += radioCalculatorType(value[key], values, weight, max);
  });

  return Math.min(points * weight, max);
}

function textCalculatorType(value, point, weight, max) {
  if (value) {
    return Math.min(point * weight, max);
  }

  return 0;
}

const experienceCalculator = (experience) => {
  const calculator = {
    AdequadaLGBT: {
      type: 'radio',
      values: {
        'Sim, é direcionada': 1,
        'Sim, é adequada': 1,
        'Não atende': 0,
      },
      weight: 10,
      maxPoints: 10,
      block: 'preparation',
    },
    AdequadaAcessibilidade: {
      type: 'checkbox',
      values: {
        'Não atende': 0,
        'Para cadeirantes ou pessoas com mobilidade reduzida': 1,
        'Para pessoas com deficiência física': 1,
        'Para pessoas com deficiência visual': 1,
        'Para pessoas com deficiência auditiva': 1,
        'Para pessoas surdocegas': 1,
        'Para pessoas com deficiência intelectual ou mental': 1,
        'Para pessoas no espectro autista': 1,
        'Outro': 1,
      },
      weight: 10,
      maxPoints: 10,
      block: 'preparation',
    },
    AdequadaIdoso: {
      type: 'radio',
      values: {
        'Sim, é direcionada': 1,
        'Sim, é adequada': 1,
        'Não atende': 0,
      },
      weight: 5,
      maxPoints: 5,
      block: 'preparation',
    },
    AdequadaCrianca: {
      type: 'radio',
      values: {
        'Sim, é direcionada': 1,
        'Sim, é adequada': 1,
        'Não atende': 0,
        'Não atende, pois é para maiores de idade': 0,
      },
      weight: 5,
      maxPoints: 5,
      block: 'preparation',
    },
    RecebeEstrangeiro: {
      type: 'checkboxCount',
      values: {
        'Não recebe estrangeiros': 1,
        'Não sei especificar': 1,
        'Alemanha': 1,
        'Argentina': 1,
        'Austrália': 1,
        'Bélgica': 1,
        'Bolívia': 1,
        'Canadá': 1,
        'Chile': 1,
        'China': 1,
        'Colômbia': 1,
        'Espanha': 1,
        'Estados Unidos': 1,
        'França': 1,
        'Itália': 1,
        'Paraguai': 1,
        'Japão': 1,
        'México': 1,
        'Países Baixos': 1,
        'Peru': 1,
        'Portugal': 1,
        'Reino Unido': 1,
        'Suíça': 1,
        'Uruguai': 1,
        'Outro': 1,
      },
      weight: 4,
      maxPoints: 16,
      block: 'promotion',
    },
    Idiomas: {
      type: 'multipleRadio',
      values: {
        'Não atende': 0,
        'Atende parcialmente': 0,
        'Atende totalmente': 2,
      },
      weight: 1,
      maxPoints: 10,
      block: 'preparation',
    },
    ContratarAtendimentoIdioma: {
      type: 'radio',
      values: {
        'Sim': 1,
        'Não': 0,
        'Dependendo da situação': 0,
      },
      weight: 5,
      maxPoints: 5,
      block: 'preparation',
    },
    TipoExperiencia: {
      type: 'radio',
      values: {
        'Sim, sustentabilidade ambiental': 1,
        'Sim, sustentabilidade social': 1,
        'Sim, ambas': 2,
        'Não': 0,
      },
      weight: 10,
      maxPoints: 20,
      block: 'preparation',
    },
    DesenvolvimentoSustentavel: {
      type: 'checkbox',
      values: {
        'Não está aderente a nenhum ODS': 0,
        'ODS 1 - Erradicação da pobreza': 1,
        'ODS 2 - Fome zero e agricultura sustentável': 1,
        'ODS 3 - Saúde e bem-estar': 1,
        'ODS 4 - Educação de qualidade': 1,
        'ODS 5 - Igualdade de gênero': 1,
        'ODS 6 - Água limpa e saneamento': 1,
        'ODS 7 - Energia limpa e acessível': 1,
        'ODS 8 - Trabalho decente e crescimento econômico': 1,
        'ODS 9 - Inovação e infraestrutura': 1,
        'ODS 10 - Redução das desigualdades': 1,
        'ODS 11 - Cidades e comunidades sustentáveis': 1,
        'ODS 12 - Consumo e produção responsáveis': 1,
        'ODS 13 - Ação contra a mudança global do clima': 1,
        'ODS 14 - Vida na água': 1,
        'ODS 15 - Vida terrestre': 1,
        'ODS 16 - Paz, justiça e instituições eficazes': 1,
        'ODS 17 - Parcerias e meios de implementação': 1,
      },
      weight: 5,
      maxPoints: 5,
      block: 'preparation',
    },
    ExperienciaInternacional: {
      type: 'checkbox',
      values: {
        'Não é promovida internacionalmente': 0,
        'Campanhas de mídia off-line': 1,
        'Campanhas de mídia digital': 1,
        'Eventos segmentados': 2,
        'Feiras multiprodutos': 2,
        'Materiais promocionais': 1,
        'Roadshows': 2,
        'RP (Relações Públicas)': 2,
        'Site na internet': 1,
        'Outro': 1,
      },
      weight: 4,
      maxPoints: 52,
      block: 'promotion',
    },
    ComercioInternacional: {
      type: 'checkbox',
      values: {
        'Não possui comercialização internacional': 0,
        'Venda direta nos canais próprios da empresa': 1,
        'Por intermédio de um operador brasileiro (DMC)': 2,
        'Por intermédio de um operador internacional': 2,
        'Por meio de plataformas de comercialização/brokers internacionais': 2,
        'Outro': 1,
      },
      weight: 4,
      maxPoints: 32,
      block: 'promotion',
    },
    Premiacao: {
      type: 'text',
      value: 3,
      weight: 10,
      maxPoints: 30,
      block: 'preparation',
    },
  };

  const keys = Object.keys(calculator);

  let totalPoints = 0;
  let totalPromotionPoints = 0;
  let totalPreparationPoints = 0;

  keys.forEach((key) => {
    if (experience[key]) {
      let currPoints;
      switch (calculator[key].type) {
        case 'radio':
          currPoints = radioCalculatorType(experience[key], calculator[key].values, calculator[key].weight, calculator[key].maxPoints);
          break;
        case 'checkbox':
          currPoints = checkboxCalculatorType(experience[key], calculator[key].values, calculator[key].weight, calculator[key].maxPoints);
          break;
        case 'checkboxCount':
          currPoints = checkboxCountCalculatorType(experience[key], calculator[key].values, calculator[key].weight, calculator[key].maxPoints);
          break;
        case 'multipleRadio':
          currPoints = multipleRadioCalculatorType(experience[key], calculator[key].values, calculator[key].weight, calculator[key].maxPoints);
          break;
        default:
          currPoints = textCalculatorType(experience[key], calculator[key].value, calculator[key].weight, calculator[key].maxPoints);
      }
      totalPoints += currPoints;

      if (calculator[key].block === 'preparation')
        totalPreparationPoints += currPoints;
      else
        totalPromotionPoints += currPoints;
    }
  });

  return { totalPoints: totalPoints / 2, totalPreparationPoints: totalPreparationPoints, totalPromotionPoints: totalPromotionPoints };
};

/* export const resetScore = async () => {
  const comp = await getAllCompanies();

  for (let indexComp = 0; indexComp < comp.length; indexComp++) {
    const docData = comp[indexComp].data();
    
    if (docData.name === 'Instituto Rede Terra') {
      if (docData.experiences && docData.experiences.length > 0) {
        const newCalcExp = [];
        for (let index = 0; index < docData.experiences.length; index++) {
          const { totalPoints, totalPreparationPoints, totalPromotionPoints } = experienceCalculator(docData.experiences[index]);
          
          console.log(totalPoints, totalPreparationPoints, totalPromotionPoints);
          newCalcExp.push({ ...docData.experiences[index], classification: totalPoints, preparationPoints: totalPreparationPoints, promotionPoints: totalPromotionPoints });
        }
        
        await firebase.firestore().collection('Companies').doc(comp[indexComp].id).set({ ...docData, experiences: newCalcExp });
      }
    }
  }

  console.log("Done!");
}; */

async function reuploadFiles(type, company, experience, uid, index, finishProcess, setCompanyInfos, setExpPopup) {
  const urlFotos = [];

  try {
    for (let indexImg = 0; indexImg < experience.urlFotos.length; indexImg++) {
      const img = experience.urlFotos[indexImg];
      if (img.file !== '') {
        const ref = firebase.storage().ref(`cae/${uid}/${img.name}`);
        await ref.getDownloadURL().then((url) => {
          urlFotos.push({
            name: img.name,
            lastModifiedDate: img.lastModifiedDate,
            size: img.size,
            src: url,
            file: '',
          });
        });
      } else
        urlFotos.push(img);
    }

    const newExperiences = company.experiences;
    const { totalPoints, totalPreparationPoints, totalPromotionPoints } = experienceCalculator({ ...experience, urlFotos: urlFotos });
    const createdExperience = { ...experience, urlFotos: urlFotos, classification: totalPoints, preparationPoints: totalPreparationPoints, promotionPoints: totalPromotionPoints };

    if (type === 'create')
      newExperiences.push(createdExperience);
    else
      newExperiences[index] = createdExperience;

    await firebase.firestore().collection('Companies').doc(uid).set({ ...company, experiences: newExperiences });

    setCompanyInfos({ ...company, experiences: newExperiences });
    finishProcess(false);
    setExpPopup(true);

    return;
  } catch (error) {
    console.log(error);
  }
}